import React, { Component } from "react";
import Button from "./base/Button";
const contentful = require("contentful");

interface Props {
  limit?: number;
}

export default class Members extends Component<Props> {
  state = { members: [] };

  componentDidMount = async () => {
    const client = contentful.createClient({
      space: process.env.GATSBY_CONTENTFUL_SPACE,
      accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
    });

    try {
      const entries = await client.getEntries({
        content_type: "member",
        order: "fields.name",
      });
      const memberList: any = [];
      entries.items.forEach(function (entry: any) {
        memberList.push({
          name: entry.fields.name,
          location: entry.fields.location,
          imageUrl: entry.fields.picture.fields.file.url,
          role: entry.fields.profession,
          linkedinUrl: entry.fields.linkedin,
        });
      });

      this.setState({
        members: memberList,
      });
    } catch (err) {}
  };

  renderMembers = () => {
    const memberListView: any[] = [];
    this.state.members.map((person, index) => {
      if (this.props.limit && index + 1 > this.props.limit) {
        return null;
      }

      memberListView.push(
        <li key={index}>
          <div className="space-y-6">
            <img
              className="mx-auto h-40 w-40 object-cover rounded-full xl:w-56 xl:h-56"
              src={person["imageUrl"]}
              alt=""
            />
            <div className="space-y-2">
              <div className="text-lg leading-6 font-medium space-y-1">
                <h3>{person["name"]}</h3>
                <p className="text-blue-600">{person["role"]}</p>
                <p className="text-xs text-gray-600 py-1">
                  {person["location"]}
                </p>
              </div>
              <ul className="flex justify-center space-x-5">
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={person["linkedinUrl"]}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">LinkedIn</span>
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      );
      return null;
    });

    return memberListView;
  };

  render() {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12">
            <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
              <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                Meet DMAP's Members
              </h2>
              <p className="text-lg text-gray-500">
                Our community aims to support any of your tech-related
                endeavors, provide a forum for you to mentor one another, and
                develop leadership skills thereby multiplying your impact.
              </p>
            </div>
            <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-7xl">
              {this.renderMembers()}
            </ul>
            {this.props.limit && (
              <Button
                className="max-w-xs mx-auto"
                title="View More Members"
                link="/members"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
