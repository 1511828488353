import React, { Component } from "react";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Members from "../components/Members";
import SEO from "../components/Seo";
import MembersBg from "../images/profile_bg.webp";

export default class MembersPage extends Component {
  render() {
    return (
      <div className="relative bg-white overflow-hidden">
        <SEO
          title="DMAP - Members"
          description="DMAP (Digital Makers Asia Pacific is a team of Software Engineers and Developers that got together by participating in one or more of the APEC App Challenge events. We are aiming to build a community of professionals making an impact in the world!"
        />
        <Hero
          title="Expanding Across"
          titleBlue="APEC Economies"
          description="Our members come from a myriad of backgrounds, cultures, and APEC economies. As a group, we promote collaboration among our members despite the differences in languages and time zones."
          image={MembersBg}
        />
        <Members />
        <Footer />
      </div>
    );
  }
}
